<template>
  <b-container fluid>
    <AlertExpirePassword />

    <div class="kt-container">
      <b-col sm="12" md="12" class="px-0">
        <ContentLoad
          v-bind="{
            display: pageLoading
          }"
        ></ContentLoad>
        <ContentLoadError
          v-bind="{
            display: pageLoadingError
          }"
        ></ContentLoadError>

        <b-card border-variant="secondary" header-border-variant="secondary">
          <b-row>
            <b-col
              xs="12"
              md="8"
              justify="center"
              class="align-middle d-inline"
            >
              <b-form-input
                id="input-email"
                v-model="form.email"
                disabled
                placeholder="Email"
              ></b-form-input>
            </b-col>

            <b-col
              xs="12"
              md="4"
              justify="center"
              class="align-middle m-auto text-center md-mt-3"
            >
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="6" justify="center" class="align-middle mt-sm-3">
              <label for="input-firstname">First name:</label>

              <b-form-input
                id="input-firstname"
                v-model="form.firstName"
                required
                placeholder="First name"
              ></b-form-input>
            </b-col>

            <b-col sm="12" md="6" justify="center" class="align-middle mt-sm-3">
              <label for="input-lastname">Last name:</label>

              <b-form-input
                id="input-lastname"
                v-model="form.lastName"
                required
                placeholder="Last name"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="6" justify="center" class="align-middle mt-sm-3">
              <label for="input-password-current">Current password:</label>

              <b-form-input
                type="password"
                id="input-password-current"
                v-model="form.password"
                required
                placeholder="Current password"
                :state="formSate.password"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-2-live-feedback">
                Current password is required.
              </b-form-invalid-feedback>
            </b-col>

            <b-col
              sm="12"
              md="6"
              justify="center"
              class="align-middle pt-0 mt-sm-3"
            >
              <b-row>
                <b-col sm="12" justify="center" class="align-middle">
                  <label for="input-password">New password:</label>

                  <b-form-input
                    type="password"
                    id="input-password"
                    v-model="form.newPassword"
                    required
                    placeholder="New password"
                    :state="formSate.newPassword"
                    aria-describedby="input-2-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-2-live-feedback">
                    Password should be at least 8 characters in length and
                    should include at least one upper case letter, one number,
                    and one special character.
                  </b-form-invalid-feedback>
                </b-col>

                <b-col sm="12" justify="center" class="align-middle mt-3">
                  <label for="input-password-confirm"
                    >New password confirm:</label
                  >

                  <b-form-input
                    type="password"
                    id="input-password-confirm"
                    v-model="form.confirmPassword"
                    required
                    placeholder="New password confirm"
                    :state="formSate.confirmPassword"
                    aria-describedby="input-2-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-2-live-feedback">
                    Both passwords must be the same.
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col sm="12" justify="center" class="align-middle">
              <b-button
                small
                type="submit"
                variant="primary"
                class="mx-auto"
                style="width: 130px"
                :loading="button.loading"
                :disabled="!!button.disabled"
                @click="onSubmit()"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </div>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import auth, { UPDATE_USER_PROFILE } from "@/store/auth.module";

import ContentLoadError from "@/views/partials/widgets/ContentLoadError";
import ContentLoad from "@/views/partials/widgets/ContentLoad";
import AlertExpirePassword from "@/components/AlertExpirePassword";

export default {
  name: "UserProfile",

  components: {
    ContentLoadError,
    ContentLoad,
    AlertExpirePassword
  },

  data() {
    return {
      timeout: 2000,
      pageLoading: false,
      pageLoadingError: false,
      sessions: {
        loading: true,
        sizes: {
          default: 12,
          sm: 6,
          lg: 4
        }
      },
      button: {
        loading: false,
        disabled: false
      },
      error: null,
      form: {
        lastName: auth.state.user.lastName,
        firstName: auth.state.user.firstName,
        email: auth.state.user.email,
        password: "",
        newPassword: "",
        confirmPassword: ""
      },
      formSate: {
        password: null,
        newPassword: null,
        confirmPassword: null
      }
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Manage account",
        pageCategory: "Account",
        header: {
          actions: ["submit"]
        }
      }
    ]);

    this.fn = () => {
      this.onSubmit();
    };
    this.$root.$on("submit", this.fn);
  },

  beforeDestroy() {
    this.$root.$off("submit", this.fn);
  },

  watch: {
    form: {
      handler(val) {
        const pwdValidation = this.validator(val.newPassword);
        const pwdCValidation = this.validator(val.confirmPassword);

        if (0 < val.newPassword.length) {
          this.formSate.newPassword = pwdValidation;

          if (0 === val.password.length || 8 > val.password.length) {
            this.formSate.password = false;
          } else {
            this.formSate.password = true;
          }
        } else {
          this.formSate.newPassword = null;
          this.formSate.password = null;
        }

        if (0 < val.confirmPassword.length) {
          this.formSate.confirmPassword =
            pwdCValidation &&
            this.form.newPassword === this.form.confirmPassword;
        } else {
          this.formSate.confirmPassword = null;
        }
      },
      deep: true
    }
  },

  methods: {
    validator(password) {
      return (
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[^\w]/.test(password) &&
        8 <= password.length
      );
    },

    onSubmit() {
      this.button.loading = true;
      this.button.disabled = true;
      this.pageLoading = true;

      if ("" !== this.form.newPassword) {
        if (
          !this.validator(this.form.newPassword) &&
          !this.validator(this.form.confirmPassword) &&
          this.form.confirmPassword !== this.form.newPassword
        ) {
          this.error = "Check New Password";

          this.$bvToast.toast(`${this.error}`, {
            title: "Update profile",
            variant: "danger",
            solid: true
          });

          this.button.loading = false;
          this.button.disabled = false;
          this.pageLoading = false;
          return;
        }

        if (8 > this.form.password.length) {
          this.error = "Check Current Password";

          this.$bvToast.toast(`${this.error}`, {
            title: "Update profile",
            variant: "danger",
            solid: true
          });

          this.button.loading = false;
          this.button.disabled = false;
          this.pageLoading = false;
          return;
        }
      }

      this.$store
        .dispatch(UPDATE_USER_PROFILE, {
          ...this.form,
          user_id: auth.state.user.id
        })
        // go to which page after successfully login
        .then(response => {
          this.button.loading = false;
          this.button.disabled = false;
          this.pageLoading = false;

          const result = response.data ? response.data : {};

          this.form = {
            ...this.form,
            ...result,
            password: "",
            newPassword: "",
            confirmPassword: ""
          };

          this.$bvToast.toast("Profile updated.", {
            title: "Update profile",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          this.button.loading = false;
          this.button.disabled = false;
          this.pageLoading = false;

          const err = error.response.data.errors
            ? error.response.data.errors
            : "An error has occurred.";

          this.$bvToast.toast(`${err}`, {
            title: "Update profile",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
